import { QueryClient, QueryClientProvider, useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import "dayjs/locale/en";
import localeData from "dayjs/plugin/localeData";
import localizedFormat from "dayjs/plugin/localizedFormat";
import { t } from "i18n:astro";
import { Fragment, useCallback } from "react";
import { Button } from "../../../components/layout/Button/Button";
import { ButtonStyleVariant } from "../../../components/layout/Button/types";
import { useBlogApi } from "./_api";
import "./style.scss";

dayjs.extend(localizedFormat);
dayjs.extend(localeData);
dayjs.locale("en");

const queryClient = new QueryClient();

const Insights = () => {
  const { getPosts } = useBlogApi();

  const { data: posts } = useQuery({
    queryKey: ["FETCH_BLOG_POSTS"],
    queryFn: getPosts,
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });

  const formatDate = useCallback(
    (val: string) => {
      return dayjs(val).format("MMMM D, YYYY");
    },
    [dayjs],
  );

  return (
    <div id="insights">
      <h2>{t("home:insights.title")}</h2>
      {posts && (
        <div className="items">
          {posts.map((v) => (
            <a
              className="item"
              key={v.id}
              href={v.postUrl}
              target="_blank"
              aria-label={`${v.title} article link`}
            >
              <div className="cover">
                <p className="info">{formatDate(v.publishedAt)}</p>
                <img src={v.imageUrl} alt={`${v.title} post image`} />
              </div>
              <div className="bottom">
                <p className="description">{v.title.trim()}</p>
              </div>
            </a>
          ))}
        </div>
      )}
      <div className="controls">
        <a href="/blog" target="_blank" aria-label="Read more posts link">
          <Button
            styleVariant={ButtonStyleVariant.INVERTED}
            text={t("home:insights.cta")}
          />
        </a>
      </div>
    </div>
  );
};

const InsightsContext = () => {
  return (
    <Fragment>
      <QueryClientProvider client={queryClient}>
        <Insights />
      </QueryClientProvider>
    </Fragment>
  );
};

export default InsightsContext;
